<template>
  <v-app style="background-color: white">
    <v-app-bar
      v-if="$vuetify.breakpoint.smAndUp"
      app
      flat
      height="117"
      color="black"
      ref="appBar"
    >
      <v-img
        aaspect-ratio="249 / 1244"
        class="logo ma-0 ml-0 home-link"
        alt="Logo"
        contain
        transition="false"
        height="64px"
        max-width="200px"
        src="@/assets/logo.svg"
        @click.stop="goHome"
        style="display: inline-block"
      />
      <span class="title text-no-wrap home-link" @click.stop="goHome">
        TeleEntreprenad
      </span>
      <v-spacer />
      <template v-for="(link, i) in menu">
        <div
          :key="i"
          @click.stop="linkTo(link.to)"
          :class="{ 'menu-item': true, current: currentId == link.to }"
        >
          {{ link.title }}
        </div>
      </template>
    </v-app-bar>

    <v-app-bar
      v-else
      app
      flat
      height="84"
      color="black"
      class="menu-bar"
      ref="appBar"
    >
      <v-app-bar-nav-icon @click="showDrawer = !showDrawer"
        ><v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <v-img
        :aspect-ratio="249 / 1244"
        class="logo ma-0"
        alt="Logo"
        contain
        transition="false"
        height="40px"
        max-width="100px"
        src="@/assets/logo.svg"
        @click.stop="goHome"
      />
      <span class="title-small text-no-wrap" @click.stop="goHome">
        TeleEntreprenad
      </span>
    </v-app-bar>

    <v-navigation-drawer
      app
      fixed
      class="drawer"
      v-if="$vuetify.breakpoint.mobile"
      v-model="showDrawer"
      :style="
        $vuetify.breakpoint.mobile
          ? `margin-top: ${$vuetify.application.top}px`
          : 'padding-top: 10px'
      "
      color="black"
      :temporary="$vuetify.breakpoint.mobile"
      :permanent="!$vuetify.breakpoint.mobile"
    >
      <v-list nav dense dark active-class="drawer-item">
        <template v-for="(link, i) in menu">
          <v-list-item :key="i" @click.stop="linkTo(link.to)">
            <v-list-item-title
              :class="{ 'drawer-item': true, current: currentId == link.to }"
              >{{ link.title }}</v-list-item-title
            >
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main v-if="!error">
      <div
        class="content"
        v-scroll="onScroll"
        v-show="!showAllProjects"
        ref="content"
        id="content"
      >
        <Intro id="om_oss" />
        <!-- <IntroText
          id="services"
          :data-size-class="$vuetify.breakpoint.smAndUp ? '0' : ''"
        /> -->
        <BaseSection
          title="Våra tjänster"
          subtitle="Inom vart och ett av dessa områden har vi mycket stor kompetens och erfarenhet. Ta gärna kontakt med oss och diskutera behov och lösningar."
          id="services"
          background="background-1"
        >
          <v-row justify="center">
            <v-col cols="12" sm="6" class="pr-sm-8">
              <v-img
                style="background-color: grey"
                :src="
                  $vuetify.breakpoint.smAndUp
                    ? require('@/assets/images/services/mobile/Optoblasning.jpg')
                    : require('@/assets/images/services/mobile/Optoblasning.jpg')
                "
                :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1.0 : 1.5"
                cover
                transition="false"
              />
              <div class="section-text mt-4"><b>Optoblåsning</b></div>
              <div class="section-text">
                Vi är utbildade och har utrustning för blåsning av de flesta
                förekommande storlekarna på kabel och dukt. Vi har även
                utrustning för kontroll och felsökning vid duktförläggning. Vi
                är godkända för TRV OPTO 2.0.
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pl-sm-8">
              <v-img
                style="background-color: grey"
                :src="
                  $vuetify.breakpoint.smAndUp
                    ? require('@/assets/images/services/mobile/Optoskarvning.jpg')
                    : require('@/assets/images/services/mobile/Optoskarvning.jpg')
                "
                :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1.0 : 1.5"
                cover
                transition="false"
              />
              <div class="section-text mt-4"><b>Optoskarvning</b></div>
              <div class="section-text">
                Våra tekniker är certifierade Fibertekniker enligt kraven från
                SEK och INCERT. Vi utför skarvning och mätningar på alla typer
                av optokabel.
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="6" class="pr-sm-8">
              <v-img
                style="background-color: grey"
                :src="
                  $vuetify.breakpoint.smAndUp
                    ? require('@/assets/images/services/mobile/MOK_kopparkabel.jpg')
                    : require('@/assets/images/services/mobile/MOK_kopparkabel.jpg')
                "
                :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1.0 : 1.5"
                cover
                transition="false"
              />
              <div class="section-text mt-4"><b>MOK- och kopparkabel</b></div>
              <div class="section-text">
                Vi är certifierade enligt TDOK 2012:1046. Utför alla slags
                arbeten med kopparkabel i Trafikverkets anläggningar såsom
                MOK-Skarv, T-avgreningar, Lokalkablar med mera.
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pl-sm-8">
              <v-img
                style="background-color: grey"
                :src="
                  $vuetify.breakpoint.smAndUp
                    ? require('@/assets/images/services/mobile/Informationssystem.jpg')
                    : require('@/assets/images/services/mobile/Informationssystem.jpg')
                "
                :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1.0 : 1.5"
                cover
                transition="false"
              />
              <div class="section-text mt-4"><b>Informationssystem</b></div>
              <div class="section-text">
                Vi har stor kunskap i byggnation och driftsättning av
                Trafikverkets Informationssystem. Vi installerar och
                konfigurerar allt från högtalarsystem till Dynamiska skyltar.
              </div>
            </v-col>
          </v-row>
        </BaseSection>

        <BaseSection
          title="Våra projekt"
          subtitle="Här ser du några av våra projekt på senare tid.
Ta gärna kontakt med oss och diskutera projekten,
här finns mycket att lära för framtida planering."
          id="projects"
          background="background-2"
        >
          <v-row justify="center">
            <v-col cols="12" sm="4">
              <Project :project="projects[0]" />
            </v-col>
            <v-col cols="12" sm="4">
              <Project :project="projects[1]" />
            </v-col>
            <v-col cols="12" sm="4">
              <Project :project="projects[2]" />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col justify="center" cols="auto">
              <v-btn class="orange-button" text @click.stop="showProjects">
                <span class="mr-2">Se fler av våra projekt</span>
              </v-btn>
            </v-col>
          </v-row>
        </BaseSection>

        <BaseSection title="Karriär" id="careers" background="background-3">
          <div class="section-text career-text">
            <p
              class="pt-5"
              v-html="
                require('!!raw-loader!@/assets/content/career.txt').default
              "
            />
          </div>
          <!-- <v-row justify="center">
            <v-col justify="center" cols="auto">
              <v-btn class="orange-button" text @click.stop="showLoginScreen">
                <span class="mr-2">Se våra jobbannonser</span>
              </v-btn>
            </v-col>
          </v-row> -->
        </BaseSection>

        <Contact id="kontakta_oss" background="background-1" />
        <Footer background="background-2" />
      </div>

      <div
        :class="{'content': true, 'all-projects': true, 'visible': showAllProjects}"
        ref="projectspage"
      >
        <div class="section">
          <span class="section-content back" @click="hideProjects"
            >&lt; Tillbaka</span
          >
        </div>
        <BaseSection
          title="Våra projekt"
          subtitle=""
          id="all-projects"
          background="background-2"
          :data-size-class="$vuetify.breakpoint.smAndUp ? '0' : ''"
        >
          <v-row justify="start" class="ma-0 pa-0">
            <v-col cols="12" sm="8" class="ma-0 pa-0">
              <v-container fluid class="ma-0 pa-0">
                <v-row justify="start">
                  <v-col v-for="p in projects" :key="p.title" cols="12" md="6">
              <Project :project="p" />
            </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" sm="4" class="pl-0 pl-sm-6">
              <div class="elfsight-app-0b64e158-30c9-4239-b517-990fbf6c218a"></div>
            </v-col>
            </v-row>
        </BaseSection>
      </div>

      <!-- Cookie information -->
      <cookie-law
        theme="iordning"
        buttonText="okej"
        buttonLinkText="Mer information"
        buttonLink="./cookies.html"
        buttonLinkNewTab
      >
        <div slot="message">
          <b>Kaka söker maka</b><br />Vi arbetar med byggnation av
          teleanläggningar över hela landet. Vill du anlita oss eller arbeta hos
          oss bjuder vi gärna på kaffe och kaka! Kakor använder vi även på vår
          hemsida för att du ska få en bättre upplevelse, analysera trafiken och
          använda för digital marknadsföring.
        </div>
      </cookie-law>
    </v-main>
    <v-main v-else>
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
        <Error />
      </div>
    </v-main>

    <svg
      v-if="!error"
      class="flash"
      viewBox="0 0 1088 1172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="z-index: 2"
    >
      <path
        d="M736.888 118.905L372.789 731.655L528.649 699.361L305.668 1035.42L376.156 1171.19L971.855 501.097L743.82 531.598L1049.69 118.864L736.888 118.905Z"
        fill="white"
        fill-opacity="0.3"
      />
    </svg>
  </v-app>
</template>

<script>
import Intro from "./components/Intro.vue";
import BaseSection from "./components/BaseSection";
import Contact from "./components/Contact.vue";
import CookieLaw from "vue-cookie-law";
import Footer from "./components/Footer";
import Project from "./components/Project";
import getAllProjects from "./assets/content/projects.js";
import Error from "./components/Error";

export default {
  name: "App",

  components: {
    Intro,
    BaseSection,
    Contact,
    CookieLaw,
    Footer,
    Project,
    Error,
  },

  props: {
    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    savedScroll: 0,
    showDrawer: false,
    showLogin: false,
    showAllProjects: false,
    currentId: "om_oss",
    currentIdx: null,
    scrollDisabled: false,
    projects: [],
    menu: [
      {
        title: "Om oss",
        to: "om_oss",
      },
      {
        title: "Våra tjänster",
        to: "services",
      },
      {
        title: "Våra projekt",
        to: "projects",
      },
      {
        title: "Karriär",
        to: "careers",
      },
      {
        title: "Kontakta oss",
        to: "kontakta_oss",
      },
    ],
  }),

  mounted() {
    this.projects = getAllProjects();
    this.recalcSizeClasses();
    const hash =  window.location.hash;
    if (hash && hash.length > 1 && hash[0] == '#') {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
        this.linkTo(hash.substring(1));
      });
    });
  }
  },

  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    scrollOptions() {
      return { duration: 300, offset: 0, easing: "easeInOutCubic" };
    },
  },
  methods: {
    onResize() {
      // Remove old size contraints
      for (var i = 0; i < this.$refs.content.children.length; i++) {
        const child = this.$refs.content.children[i];
        child.style.minHeight = 0;
      }
      this.$nextTick(() => {
        this.recalcSizeClasses();
      });
    },

    /**
     * To make elements "same height as x" we use HTML atrtibutes to set "data-size-class" to a class value. Then we look at ALL
     * the content child elements that have the same class value and set a minimum size to be the largest of the elements.
     */
    recalcSizeClasses() {
      var sizeClasses = {};

      // Gather size classes from elements. Group them by class.
      for (var i = 0; i < this.$refs.content.children.length; i++) {
        const child = this.$refs.content.children[i];
        const attr = child.getAttribute("data-size-class");
        if (attr && attr.length > 0) {
          if (!sizeClasses[attr]) {
            sizeClasses[attr] = [];
          }
          sizeClasses[attr].push(child);
        }
      }

      // Process them
      for (const sizeClass of Object.keys(sizeClasses)) {
        const elements = sizeClasses[sizeClass];
        if (!elements || elements.length < 2) {
          // If less than 2 elements we have nothing to compare against...
          continue;
        }
        var maxHeight = 0;
        for (var el of elements) {
          const r = el.getBoundingClientRect();
          if (r.height > maxHeight) {
            maxHeight = r.height;
          }
        }
        // Second pass, set min height data
        for (el of elements) {
          el.style.minHeight = "" + maxHeight + "px";
        }
      }
    },

    linkTo(id) {
      // If error page, link to "Main"
      if (this.error) {
        window.location.href = "./#" + id;
        return;
      }
      if (this.showAllProjects) {
        this.showAllProjects = false;
        this.$nextTick(() => {
          document.documentElement.scrollTop = this.savedScroll;
          this.savedScroll = 0;
          this.$nextTick(() => {
            // Call recursively here
            this.linkTo(id);
          });
        });
      } else {
        this.showDrawer = false;
        if (id) {
          // Log pageview
          this.$gtag.pageview({page_title: id});

          this.scrollDisabled = true;
          setTimeout(() => {
            this.scrollDisabled = false;
          }, 1500);
          this.$vuetify.goTo("#" + id, this.scrollOptions);
          this.currentId = id;
          var idx = null;
          for (var i = 0; i < this.$refs.content.children.length; i++) {
            const child = this.$refs.content.children[i];
            if (child.id == id) {
              idx = i;
              break;
            }
          }
          this.currentIdx = idx;
        }
      }
    },
    showProjects() {
      // Log pageview
      this.$gtag.pageview({page_title: "all_projects"});
      this.savedScroll = document.documentElement.scrollTop;
      this.showAllProjects = true;
      document.documentElement.scrollTop = 0;
    },
    hideProjects() {
      this.showAllProjects = false;
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.savedScroll;
      });
    },
    goHome() {
      if (this.error) {
        window.location.href = "./";
        return;
      }
      if (this.showAllProjects) {
        this.showAllProjects = false;
        this.$nextTick(() => {
          document.documentElement.scrollTop = this.savedScroll;
          this.savedScroll = 0;
          this.$nextTick(() => {
            this.scrollToTop();
          });
        });
      } else {
        this.scrollToTop();
      }
    },
    scrollToTop() {
      this.scrollDisabled = true;
      setTimeout(() => {
        this.scrollDisabled = false;
        this.onScroll();
      }, 1500);
      this.$vuetify.goTo("#om_oss", this.scrollOptions);
      this.currentId = "om_oss";
      this.currentIdx = null;
    },
    hideLogin() {
      this.showLogin = false;
    },
    onScroll() {
      if (this.scrollDisabled) {
        return;
      }
      const rect = this.$refs.appBar.$el.getBoundingClientRect();
      for (var i = 0; i < this.$refs.content.children.length; i++) {
        const child = this.$refs.content.children[i];
        if (!child.id) {
          continue;
        }
        const r = child.getBoundingClientRect();
        if (this.currentIdx != null && i < this.currentIdx) {
          // Scrolling up. Top must cross top in that case!
          if (r.top >= rect.bottom && r.bottom > rect.bottom) {
            this.currentId = child.id;
            this.currentIdx = i;
            return;
          }
        } else if (r.top < rect.bottom && r.bottom >= rect.bottom) {
          if ((r.bottom + r.top) / 2 >= rect.bottom) {
            // More than half is hidden
            if (i + 1 < this.$refs.content.children.length) {
              const c = this.$refs.content.children[i + 1];
              if (c.id) {
                this.currentId = c.id;
                this.currentIdx = i + 1;
                return;
              }
            }
          } else {
            this.currentId = child.id;
            this.currentIdx = i;
            return;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
